@charset "UTF-8";
*, *:after, *:before { -webkit-box-sizing: border-box; box-sizing: border-box; }
@import url('https://fonts.googleapis.com/css2?family=Yantramanav:wght@400;500&family=Noto+Sans+JP:wght@400;500&display=swap');

/* ==================================================================
 *  reset
 * ================================================================== */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
display: block;
}
body {
line-height: 1;
}
ol, ul {
list-style: none;
}
blockquote, q {
quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
content: '';
content: none;
}
table {
border-collapse: collapse;
border-spacing: 0;
}

/* ==================================================================
 *  Base
 * ================================================================== */

$SP-width: 768px;
$PC-width: 1200px;

@mixin SP {
  @media (max-width: 415px) {
    @content;
  }
}
@mixin TB {
  @media (max-width: 1020px) and (min-width: $SP-width) {
    @content;
  }
}

@mixin PC {
  @media (min-width: 960px) {
    @content;
  }
}

@function rem($size) {
  @if (unit($size) != px) {
    @error "$size must be pixels.";
  }
  @return ($size / 16px) * 1rem;
}

html,body {
  font-family: 'Yantramanav', 'Noto Sans JP', "メイリオ", sans-serif;
  color: #394662;
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust:100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: #fff;
  font-size: 16px;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

main{
  width: 100%;
  flex-grow: 1;
  padding-bottom: 35px;
}

a{
  color: #394662;
  transition: all 0.3s linear;
  text-decoration: none;
  &:hover{
    opacity: 0.8;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

h2 {
  margin: 50px auto;
  font-size: 12px;
  text-align: center;
  color: #394662;
  img {
    filter: drop-shadow(0px 5px 6px rgba(0,0,0,.16));
  }
  span {
    display: block;
    margin-top: 12px;
  }
}

.join_btn,input[type="submit"] {
  background: #1A52CE;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 3.5rem;
  font-weight: bold;
  border-radius: 2rem;
  font-size: 18px;
  position: relative;
  border: none;
  cursor: pointer;
  padding-top: 2px;
  &.cancel {
    background: #f72b4f;
  }
  &.arrow {
    &:after {
      content: "";
      background: url("./image/arrow.svg") no-repeat;
      background-size: 100%;
      width: 2em;
      height: 0.5em;
      position: absolute;
      right: 1.5em;
    }
  }
  &.facebook {
    &:before {
      content: "";
      background: url("./image/facebook.svg") no-repeat;
      background-size: 100%;
      width: 1.9em;
      height: 1.9em;
      position: absolute;
      left: 1em;
    }
  }
}

.note {
  font-size: 80%;
  text-align-last: left;
  text-indent: -1em;
  padding-left: 0.5em;
  line-height: 1.3;
  display: inline-block;
}

/* ==================================================================
 *  header
 * ================================================================== */

header {
  position: fixed;
  z-index: 1000;
  padding: 10px 3vw;
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  h1 {
    max-width: 50%;
  }
  .join_btn {
    line-height: 2.4rem;
    border-radius: 1.5rem;
    font-size: 1em;
    width: 120px;
    display: block;
    &:before,&:after{
      display: none;
    }
  }
}

/* ==================================================================
 *  top
 * ================================================================== */

.top {
  background-color: #fff;
  height: 100vh;
  max-height: 800px;
  padding: 60px 6vw 50px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  @include TB {
    background-size: auto 65vw;
    background-position: 110% 0%;
    height: 65vw;
  }
  @include SP {
    max-height: calc(100vw * 1.75);
    padding: 20px 6vw;
    z-index: 1000;
  }
  .top_image {
    position: absolute;
    right: 4vw;
    width: 50%;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    svg {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
    @include SP {
      width: 105%;
      height: 100vw;
      right: -14%;
      top: 20%;
    }
  }
  .top_title {
    margin: auto 0 60px;
    z-index: 2;
    p {
      padding-left: 1em;
      margin-top: 2em;
      font-weight: bold;
      color: #0C348B;
      line-height: 1.4;
    }
    img {
      filter: drop-shadow(0px 5px 6px rgba(0,0,0,.16));
    }
    @include TB {
      img {
        width: 40%;
        margin: 30px 0 0 10px;
      }
    }
    @include SP {
      margin: 0 0 auto;
      img {
        width: 70%;
      }
      p {
        padding-left: 1vw;
        font-size: 4vw;
        margin-top: 20px;
        color: #465a86;
      }
    }
  }
  .top_enrollment {
    max-width: 370px;
    display: flex;
    flex-wrap: wrap;
    font-size: 95%;
    z-index: 2;
    color: #0C348B;
    margin-bottom: auto;
    @include TB {
      max-width: 300px;
    }
    @include SP {
      width: 100%;
      justify-content: center;
      position: sticky;
      bottom: 50px;
      margin: 0;
    }
    .member {
      background: url("./image/users.svg") no-repeat left center;
      background-size: 20px;
      padding: 2px 0 0 26px;
      line-height: 20px;
      margin-right: 10px;
      margin-left: 1em;
      font-size: rem(14px);
      z-index: 3;
      span {
        font-size: 120%;
      }
      &:after {
        content: "/";
        margin-left: 5px;
        font-size: 120%;
      }
      @include SP {
        margin-left: 0;
      }
    }
    .price {
      line-height: 20px;
      z-index: 3;
      font-size: rem(14px);
      padding-top: 3px ;
      span {
        letter-spacing: 0.5px;
        margin-left: 5px;
        font-size: 115%;
      }
    }
    .join_btn {
      width: 100%;
      margin-top: 20px;
      @include SP {
        order: -1;
        margin-bottom: 10px;
      }
    }
  }
}

/* ==================================================================
 *  about
 * ================================================================== */

.about {
  text-align: center;
  width: 100%;
  font-size: rem(15px);
  margin: 0 auto;
  padding: 10px 0 100px;
  background: #F3F7FE;
  p {
    line-height: 2.5;
    width: 90%;
    max-width: 850px;
    margin: 0 auto;
    @include TB {
      font-size: rem(13px);
      width: 100%;
    }
    @include SP {
      width: 85%;
      font-size: rem(12px);
      text-align: justify;
      br {
        display: none;
      }
    }
    strong {
      font-weight: bold;
    }
  }
}

 /* ==================================================================
 *  contents
 * ================================================================== */

.contents {
  width: calc(100% - 50px);
  max-width: 1000px;
  margin: 150px auto 100px;
  &_list {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    @include SP {
      flex-direction: column;
    }
    li {
      width: 27.3%;
      margin: 0 3% 5%;
      @include SP {
        width: 90%;
        margin: 0 auto 60px;
      }
      h3 {
        font-weight: bold;
        margin: 1.5em auto;
        height: 2.3em;
        text-align: center;
        color: #1A52CE;
        line-height: 1.3;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      p {
        line-height: 1.8;
        font-size: rem(14px);
        word-break: break-all;
      }
    }
  }
}

/* ==================================================================
 *  howto
 * ================================================================== */

.howto {
  width: 100%;
  margin: 50px auto 150px;
  text-align: center;
  &_list {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 50px;
    width: calc(100% - 50px);
    max-width: 1000px;
    @include SP {
      flex-direction: column;
      margin-bottom: 0;
    }
    li {
      width: 30%;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 2px 60px rgba(76,86,100, 0.2);
      position: relative;
      @include SP {
        width: 85%;
        margin: 0 auto 60px;
        &:not(.on):last-child {
          margin-bottom: 30px;
        }
      }
      &:before {
        content: "";
        width: 50px;
        height: 50px;
        position: absolute;
        top: -15px;
        left: -15px;
      }
      &:nth-child(1):before {
        background: url("./image/step_01.svg") no-repeat;
        background-size: contain;
      }
      &:nth-child(2):before {
        background: url("./image/step_02.svg") no-repeat;
        background-size: contain;
      }
      &:nth-child(3):before {
        background: url("./image/step_03.svg") no-repeat;
        background-size: contain;
      }
      &:not(:last-child):after,
      &.on:after {
        content: "";
        position: absolute;
        right: -12%;
        top: 50%;
        margin-top: -25px;
        border-left: 16px solid #AEC1DB;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        @include SP {
          top: calc(100% + 18px);
          right: 50%;
          margin: 0 -5px 0 0;
          transform: rotate(90deg);
        }
      }
      &:last-child.on:after {
        @media (min-width: 768px) {
          display: none;
        }
      }
      h3 {
        height: 2.6em;
        font-weight: bold;
        margin: 1em 0;
        font-size: rem(15px);
        line-height: 1.3;
        justify-content: center;
        align-items: center;
        display: flex;
        color: #1A52CE;
      }
    }
  }
  .join_btn {
    max-width: 370px;
    width: calc(100% - 50px);
    margin: 30px auto;
  }
}

/* ==================================================================
 *  Participate
 * ================================================================== */

.participate {
  padding: 20px 0 50px;
  background: #F3F7FE;
  text-align: center;
  &:before {
    content: "";
    position: absolute;
    background: #F3F7FE;
    width: 100%;
    height: 60px;
    top: 0;
    left: 0;
    z-index: 9999;
    mix-blend-mode: darken;
    pointer-events: none;
  }

  h2 {
    margin-bottom: 30px;
  }

  .read {
    line-height: 1.6;
    margin-bottom: 40px;
    font-size: rem(15px);
    @include SP {
      font-size: rem(12px);
    }
  }

  .howto_list li:not(.on){
    opacity: 0.3;
    box-shadow: none;
    @include SP {
      display: none;
    }
  }

  .participate_form {
    margin: 0 auto;
    width: calc(100% - 50px);
    max-width: 1000px;

    ul {
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto 40px;
      li {
        label {
          display: table;
          font-weight: bold;
          font-size: 80%;
          margin-bottom: 10px;
          @include SP {
            font-size: rem(12px);
          }
        }
        input {
          border: none;
          background: #fff;
          color: currentColor;
          border-radius: 6px;
          line-height: 40px;
          padding: 0 10px;
          outline: 0;
          min-width: 60px;
          &::-webkit-autofill {
            box-shadow: 0 0 0 1000px #fff inset;
          }
          &:focus{
            box-shadow: 0 0 0 2px #1A52CE;
          }
          @include SP {
            font-size: 16px;
          }
        }
        &.card_num {
          width: 40%;
          input {
            width: 100%;
          }
          @include SP {
            width: 100%;
            margin-bottom: 20px;
          }
        }
        &.card_limit {
          width: 20%;
          margin: 0 20px;
          @include SP {
            width: 50%;
            margin-left: 0;
          }
          div {
            display: flex;
            align-items: center;
            input {
              width: 100%;
            }
            i {
              font-size: 120%;
              padding: 0 7px;
            }
          }
        }
        &.card_cvc {
          width: auto;
          @include SP {
            width: calc(50% - 20px);
            input {
              width: 100%;
            }
          }
        }
      }
    }

    .error {
      color: #f72b4f;
      font-weight: bold;
      margin-bottom: 20px;
      line-height: 1.6;
      font-size: 90%;
      & + ul li input{
        border: 2px solid #f72b4f;

      }
    }

    .join_btn,input[type="submit"] {
      width: 100%;
      max-width: 370px;
      margin: 0 auto;
    }
  }
}

/* ==================================================================
 *  profile
 * ================================================================== */

.profile {
  &_card {
    background: #f3f7fe;
    display: flex;
    padding: 40px;
    width: 85%;
    max-width: 1000px;
    margin: 0 auto 150px;
    @include SP {
      flex-direction: column-reverse;
    }
  }
  &_image {
    margin-left: auto;
    width: 20%;
    @include TB {
      img {
        transform: scale(1.3);
        transform-origin: right top;
      }
    }
    @include SP {
      margin: 10px auto 15px;
      width: 100%;
    }
  }
  &_text {
    align-self: center;
    width: 80%;
    @include SP {
      width: 100%;
    }
    .name {
      font-size: rem(22px);
      font-weight: bold;
      margin-bottom: 10px;
    }
    p {
      font-size: rem(13px);
      line-height: 1.6;
      font-weight: bold;
      margin-bottom: 1em;
    }
    .history {
      font-size: rem(13px);
      margin: 1em 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      dt {
        margin-right: 20px;
        width: 11em;
        line-height: 1.7;
        font-size: 105%;
      }
      dd {
        width: 60%;
        margin-right: auto;
        line-height: 1.7;
        @include SP {
          width: 100%;
          margin-bottom: 0.5em;
        }
      }
    }
    a {
      font-size: rem(13px);
      display: inline;
      border-bottom: 1px solid;
      background: url("./image/icon_link.svg") no-repeat right center;
      background-size: 10px;
      padding-right: 15px;
      line-height: 1.7;
    }
  }
}

/* ==================================================================
 *  Q&A
 * ================================================================== */

.question {
  width: 85%;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  h3 {
    font-weight: bold;
    margin-bottom: 15px;
    border-bottom: 1px solid #cdd0d7;
    padding-bottom: 10px;
    font-size: 95%;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      margin: 0 auto;
      right: 10px;
      width: 8px;
      height: 8px;
      border-top: 1px solid #bbb;
      border-right: 1px solid #bbb;
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }
  input[type="checkbox"] {
    display: none;
    &:checked {
      &+ label{
        h3:after {
          margin-top: 3px;
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
        &+ .question_list {
          height: auto;
          opacity: 1;
          visibility: visible;
          margin-bottom: 50px;
        }
      }

    }
  }
  &_list {
    height: 0;
    opacity: 0;
    transition: height .3s linear, opacity .8s linear;
    visibility: hidden;
    dt {
      background: url("./image/question.svg") no-repeat left top;
      background-size: 38px;
      padding: 10px 0 10px 50px;
      font-weight: bold;
      margin-bottom: 10px;
      font-size: rem(15px);
      min-height: 40px;
      line-height: 1.4;
      @include SP {
        background-size: 32px;
        padding: 5px 0 5px 40px;
      }
    }
    dd {
      background: url("./image/answer.svg") no-repeat left top;
      background-size: 38px;
      padding: 10px 0 10px 50px;
      margin-bottom: 30px;
      font-size: rem(14px);
      line-height: 1.4;
      min-height: 40px;
      word-break: break-all;
      @include SP {
        background-size: 32px;
        padding: 5px 0 5px 40px;
      }
      a {
        color: #0b348b;
        font-weight: bold;
        font-size: 95%;
      }
      blockquote {
        background: #f3f7fe;
        margin-top: 15px;
        padding: 15px;
        font-size: 90%;
        h4 {
          font-weight: bold;
          margin-bottom: 10px;
        }
        p {
          margin-bottom: 10px;
        }
      }
    }
  }
}

/* ==================================================================
 *  legal
 * ================================================================== */

 .legal {
  width: calc(100% - 50px);
  max-width: 850px;
  margin: 0 auto;

  h2 {
    margin: 120px 0 60px;
    font-size: rem(25px);
    line-height: 1.4;
    color: #0b348b;
  }

  .read {
    font-size: 90%;
    line-height: 1.6;
    margin-bottom: 3em;
  }

  a {
    color: #0b348b;
    font-weight: bold;
    font-size: 95%;
  }

  ul {
    >li {
      margin-bottom: 2em;
      h3 {
        font-weight: bold;
        margin-bottom: 10px;
        border-bottom: 1px solid #cdd0d7;
        padding-bottom: 5px;
        font-size: 95%;
      }
      p {
        margin-bottom: 1em;
        font-size: 90%;
        line-height: 1.6;
      }
      ol {
        padding-bottom: 0.5em;
        line-height: 1.7;
        li {
          list-style: decimal;
          list-style-position: inside;
          padding-left: 1.2em;
          text-indent: -1em;
          font-size: 85%;
          letter-spacing: 0.1em;
          font-feature-settings: "palt";
          ol {
            counter-reset: number;
            li {
              list-style: none;
              padding-left: 2em;
              text-indent: -2.2em;
              &:before {
                counter-increment: number;
                content: "(" counter(number) ")";
                margin-right: 5px;
                font-size: 110%;
                min-width: 20px;
                text-align: right;
                display: inline-block;
              }
            }
          }
        }
      }
      ul {
        margin-left: 10px;
        line-height: 1.7;
        li {
          list-style: disc;
          list-style-position: inside;
          padding-left: 1.2em;
          text-indent: -1.4em;
          font-size: 85%;
          letter-spacing: 0.1em;
          font-feature-settings: "palt";
          margin-bottom: 5px;
          line-height: 1.4;
        }
      }
    }
  }
  p.enactment {
    font-size: 90%;
    margin: 100px 0;
    text-align: right;
  }
}

/* ==================================================================
 *  footer
 * ================================================================== */

footer {
  width: 100%;
  text-align: center;
  .foot_link {
    font-size: rem(13px);
    margin: 100px auto;
    li {
      margin-bottom: 1.5em;
    }
  }
  .copy {
    font-size: 75%;
    padding: 30px 0;
  }
}
